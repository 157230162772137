<template>
  <common>
    <main-header />
    <div class="content-bg" :class="{ 'content-bg--dark': dark }">
      <slot name="subheader"></slot>
      <div class="content-bg__content">
        <div class="container">
          <slot></slot>
        </div>
      </div>
    </div>
    <slot name="bottom"></slot>
    <main-footer class="inner-layout-footer" />
  </common>
</template>

<script lang="ts">
import MainHeader from '~/components/layout/MainHeader.vue'
import MainFooter from '~/components/layout/MainFooter.vue'
import Common from '~/layouts/common.vue'

export default {
  name: 'inner',
  components: { Common, MainFooter, MainHeader },
  props: {
    dark: { type: Boolean, default: () => false },
  },
}
</script>

<style lang="scss">
.content-bg {
  background-color: var(--hb-gray2);

  &--dark {
    background-color: var(--hb-gray1);
  }

  @include tablet {
    .hb-tab-navs {
      box-shadow: inset 0 1px 0 2px var(--hb-gray2),
        inset 0px -5px 1px -3px var(--hb-gray4);
    }
  }

  &__content {
    padding: 50px 0 100px;
    min-height: calc(100vh - 474px);

    .hb-tab-navs li {
      padding-top: 10px;
    }

    .hb-tab-navs + .hb-tabs-body .hb-tab {
      @media (min-width: 1024px) {
        padding-top: 60px;
      }
    }

    @include mobile {
      .hb-tabs {
        margin-top: -40px;
      }
    }
  }
}

.inner-layout-footer {
  margin-top: 0;
}
</style>
